.cr-header {
  margin-bottom: $spacer * .5;
  border-bottom: $border-width solid $border-color;

  &__nav-right {
    flex-direction: row;
    justify-content: flex-end;
    margin-left: auto;
  }

  .select {
    width: 250px;
    @include media-breakpoint-down(xs) {
      width: 150px;
    }
  }

  // &__notification-popover {
  //   &.popover {
  //     width: 320px;
  //     max-width: 320px;
  //     max-height: 100vh;
  //     overflow-y: scroll;
  //     cursor: pointer;
  //   }
  // }
}

.breadcrumb {
  padding: 20px;
}

.cr-page__header {
  justify-content: space-between;
}

@include media-breakpoint-down(xs) {
	nav.cr-page__breadcrumb {
    width: 100%;
  }
}