@import "../login-colors";

#login-page {
  background: linear-gradient(90deg, $background-color-one, $background-color-two);
  height: 100vh;
  overflow: hidden;

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;

    .screen {
      background: linear-gradient(90deg, $background-color-container-one, $background-color-container-two);
      position: relative;
      height: 600px;
      width: 360px;
      box-shadow: 0px 0px 24px $box-shadow;

      .screen__content {
        z-index: 1;
        position: relative;
        height: 100%;

        form {
          width: 320px;
          padding: 15px;
          padding-top: 90px;

          button {
            background: #fff;
            font-size: 14px;
            margin-top: 60px;
            padding: 16px 20px;
            border-radius: 26px;
            line-height: 27px;
            border: 1px solid $btn-border;
            text-transform: uppercase;
            font-weight: 700;
            display: flex;
            align-items: center;
            width: 100%;
            color: $btn-color;
            box-shadow: 0px 2px 2px $btn-box-shadow;
            cursor: pointer;
            transition: .2s;

            &:active, &:focus, &:hover {
              outline: none;
              border-color: $btn-actions !important;
            }

            > div {
              width: 100%;
              text-align: left;

              > div {
                float: right;
              }
            }
          }

          .img-container {
            height: 70px;

            img {
              max-width: 44%;
            }
          }

          .input-group-prepend {
            align-items: center;
            position: relative;
            left: 15px
          }

          .input-group {
            padding: 20px 0px;
            position: relative;
            width: 75%;

            .invalid-feedback {
              position: absolute;
              bottom: 0;
            }
          }

          input {
            border: none;
            border-bottom: 2px solid $input-border;
            background: none;
            padding: 10px;
            border-bottom-right-radius: 0 !important;
            padding-left: 24px;
            font-weight: 700;
            transition: .2s;

            &:active, &:focus, &:hover {
              outline: none;
              border-bottom-color: $input-actions !important;
              box-shadow: none;
            }
          }
        }
      }
    }

    .screen__background {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
      -webkit-clip-path: inset(0 0 0 0);
      clip-path: inset(0 0 0 0);
    }

    .screen__background__shape {
      transform: rotate(45deg);
      position: absolute;
    }

    .screen__background__shape1 {
      height: 520px;
      width: 520px;
      background: #FFF;
      top: -50px;
      right: 120px;
      border-radius: 0 72px 0 0;
    }

    .screen__background__shape2 {
      height: 220px;
      width: 220px;
      background: $shape-two;
      top: -172px;
      right: 0;
      border-radius: 32px;
    }

    .screen__background__shape3 {
      height: 540px;
      width: 190px;
      background: linear-gradient(270deg, $shape-three-one, $shape-three-two);
      top: -24px;
      right: 0;
      border-radius: 32px;
    }

    .screen__background__shape4 {
      height: 400px;
      width: 200px;
      background: $shape-four;
      top: 420px;
      right: 50px;
      border-radius: 60px;
    }


  }
}




