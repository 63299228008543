.mt20{ margin-top: 20px;}
.btn-add-register button {
    width: 100%;
	position: relative;
    top: 30px;
}
.btn-remove-register button {
    width: 100%;
	position: relative;
    top: 30px;
}
.btn-webcam{
	color: #fff;
	background: #6EB08C linear-gradient(180deg, #84bc9d, #6EB08C) repeat-x;
    border-color: #6EB08C;
}
.btn-webcam:hover{
	color: #fff; 
	background: #57a079 linear-gradient(180deg, #71af8d, #57a079) repeat-x;
    border-color: #539872;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
	color: #fff; 
	background: #57a079 linear-gradient(180deg, #71af8d, #57a079) repeat-x;
    border-color: #539872;
}
.webcam {
    display: flex;
    justify-content: end;
    align-items: center;
    margin: 0 auto;
}
.or-webcam {
    padding: 0px 40px 0px 40px;
}
.until {
    padding: 28px;
}
.card {
	margin-top: 20px;
}

.form-check-input {
	position: relative !important;
	float: left;
}
.input-radio {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 22px 40px 0 40px !important;
}
canvas.border {
    margin: 0 auto;
}
.asign{ margin-left: 50px;}



.displayErrors input:invalid {
	box-shadow: 0 0 0 0.2rem rgba(225, 46, 27, 0.25);
	color: #495057;
	background-color: #fff;
	border-color: #f1948a;
	outline: 0;
}

.displayErrors {
	box-shadow: 0 0 0 0.1rem rgba(225, 46, 27, 0.6);
	color: #495057;
	background-color: #fff;
	border-color: #f1948a;
	outline: 0;
	border-radius: 0.25rem;
}

@include media-breakpoint-down(md) {
	.btn-add-register button{
		margin: 0px 0px 30px 0px;
		top: 0px;
	}
	.btn-remove-register button{
		margin: 0px 0px 30px 0px;
		top: 0px;
	}

	
}

@include media-breakpoint-down(xs) {
	.webcam {
		width: 100%;
		justify-content: space-between;
	}
	.until {
		padding: 0;
		margin: 0 auto;
	}
	.input-group {
		display: grid;
	}
}

.row.form-group {
	@include media-breakpoint-down(md) {
		margin-bottom: 0 !important;
	}

	label {
		@include media-breakpoint-down(md) {
			margin-bottom: 0 !important;
		}
	}
}

.required {
	&:after {
		color: theme-color('danger');
		content: ' *';
		display: inline;
	}
}
