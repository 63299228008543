@import 'variables';

// libraries
@import '~bootstrap/scss/bootstrap.scss';

// base
@import 'base';
@import 'overrides';
@import 'extends';
@import 'animations';
@import 'utils/utils';

// components
@import 'components/header';
@import 'components/sidebar';
@import 'components/content';
@import 'components/page';
@import 'components/widget';
@import 'components/search-input';
@import 'components/forms';
@import 'components/fab';
@import 'components/radio-button';
@import 'components/table';
@import 'components/footer';
@import 'components/modal';
@import 'components/tabs';
@import 'components/date-picker';

// install components
@import '~pretty-checkbox/src/pretty-checkbox.scss';
@import '~react-tiny-fab/dist/styles.css';
@import '~react-datepicker/dist/react-datepicker.css';

// themes
@import 'themes/colors';

// pages
@import 'pages/auth';
@import 'pages/advertising-budget';

// demos (only for demo pages)
